import { template as template_a80e57e7d7964128a50d234f198f7623 } from "@ember/template-compiler";
import SortableColumn from "./sortable-column";
const ViewsCell = template_a80e57e7d7964128a50d234f198f7623(`
  <SortableColumn
    @sortable={{@sortable}}
    @number="true"
    @order="views"
    @activeOrder={{@activeOrder}}
    @changeSort={{@changeSort}}
    @ascending={{@ascending}}
    @name="views"
  />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default ViewsCell;
