import { template as template_00f8b264bdad4f3aad779f89383710f0 } from "@ember/template-compiler";
const FKText = template_00f8b264bdad4f3aad779f89383710f0(`
  <p class="form-kit-text" ...attributes>
    {{yield}}
  </p>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKText;
